
import { defineComponent } from 'vue';
import MainLogo from '@/layout/header/partials/MainLogo.vue';
import FooterCenter from '@/layout/footer/FooterCenter.vue';

export default defineComponent({
  name: 'error-404',
  components: {
    Logo: MainLogo,
    Footer: FooterCenter,
  },
});
